<template>
  <div
    v-if="page"
    class="page page-home"
    :class="{ '--is-small': !matchMedia }"
  >
    <section>
      <div class="home-background-media">
        <div class="home-background-media__wrap">
          <WidgetPicture
            v-if="page.background.image"
            :image="{ custom: page.background.image?.responsiveImage }"
            :lazyLoad="true"
            :alt="
              page.background.image?.responsiveImage?.alt !== null
                ? page.background.image.responsiveImage.alt
                : page.title
            "
            class="home-background-media__wrap_image"
          />
          <div
            v-if="page.background.videoUrl"
            class="home-background-media__wrap__video"
            data-animate="home-background-media-video-preview"
          >
            <WidgetVideo
              class="is-cover"
              :videoUrl="page.background.videoUrl"
              :loop="true"
              :forcePlay="true"
              :autoplay="true"
              :muted="true"
              :preload="'none'"
            />
          </div>
        </div>
        <div class="home-background-media__text-wrap">
          <h1 class="home-background-media__text-wrap__title">{{page.title}}</h1>
          <div
            v-for="(row, index) in page.rows"
            :key="row.id"
            class="home-background-media__text-row"
          >
            <div
              :class="{ '--odd': index % 2 != 0, '--first': index === 0 }"
              class="home-background-media__text-row-content"
            >
              <div v-html="row.title" class="title-l-1-home" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterMain />
  </div>
</template>

<script setup>
import { gsap } from 'gsap'
import queries from '@/graphql/dato/queries/index.js'

//import pageTransition from '@/assets/js/transitions/helper.js'

definePageMeta({
  layout: 'default',
  //pageTransition,
})

const { locale } = useI18n()
const route = useRoute()

const lightTheme = setLightTheme()

const matchMedia = useHelpers().evalMatchMedia('(min-width: 1100px)')

const query = queries.home.getHome
const variables = computed(() => ({
  locale: locale.value,
}))

const staggeredBlocksShown = ref(false)
const duration = 0.6
const durationFast = 0.4
const ease = 'power2.inOut'

const { data } = await useGraphql(query, variables)

if (!data.value?.home) {
  throw createError({ statusCode: 404 })
}
const page = computed(() => data.value?.home)
const seo = computed(() => data.value?.home?.seo || {})

onMounted(() => {
  lightTheme.value = true
  document.body.classList?.add('--light-theme')
  setTimeout(() => {
    lightTheme.value = true
    document.body.classList?.add('--light-theme')
  }, 300)
})

onUnmounted(() => {
  document.body.classList?.remove('--light-theme')
  lightTheme.value = false
})

// Seo
useSeo({
  ...seo.value,
})

/*watch(
  () => route.path,
  () => {
    
   

    if (
      route?.name?.includes('index') ||
      route?.name?.includes('lets-talk')
    ) {
      document.body.classList?.add('--light-theme')
      lightTheme.value = true
    } else {
      document.body.classList?.remove('--light-theme')
      lightTheme.value = false
    }
  }
)*/
</script>
